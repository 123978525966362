import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import i18n from "../i18n";

Vue.use(VueRouter);

const routes = [
    {
        path: "/auth",
        component: () => import("@/views/Index"),
        children: [
            {
                name: "login.signIn",
                path: "",
                component: () => import("@/views/Users/Login")
            }
        ]
    },
    {
        path: "/",
        name: "Main",
        component: Home,
        beforeEnter: (to, from, next) => {
            if (
                localStorage.getItem("token") &&
                localStorage.getItem("token") !== "null" &&
                localStorage.getItem("token") !== null &&
                localStorage.getItem("token") !== undefined &&
                localStorage.getItem("token") !== "undefined" &&
                localStorage.getItem("token").substr(0, 2) === "ey"
            ) {
                next();
            } else {
                next({
                    name: "login.signIn"
                });
            }
        },
        children: [
            {
                path: "/",
                name: "Dashboard",
                component: () => import("../views/Dashboard")
            },
            {
                path: "/users",
                name: "users.users",
                component: () => import("../views/Users/Users.vue")
            },
            {
                path: "/branch",
                name: "branches.branches",
                component: () => import("../views/Branches/Branch.vue")
            },
            {
                path: "/groups",
                name: "groups.groups",
                component: () => import("../views/UsersGroups/Groups.vue")
            },
            {
                path: "/changePassword",
                name: "users.changePassword",
                component: () => import("../views/Users/ChangePassword.vue")
            },
            {
                path: "/profile",
                name: "profile",
                component: () => import("../views/Users/Profile.vue")
            },
            {
                path: "/changeEmployeeSettings",
                name: "employees.ui.changeEmployeeSettings",
                component: () =>
                    import("../views/Employees/ChangeEmployeeSettings.vue")
            },
            {
                path: "/Nightshifts",
                name: "nightshifts.nightshifts",
                component: () => import("../views/Nightshifts/Nightshifts.vue")
            },
            {
                path: "/Alerts",
                name: "alerts.alerts",
                component: () => import("../views/Alerts/Alerts.vue")
            },
            {
                path: "/DeathAnnouncements",
                name: "death.deathAnnouncements",
                component: () =>
                    import("../views/DeathAnnouncements/DeathAnnouncements.vue")
            },
            {
                path: "/CompanyWebsites",
                name: "companyWebsites.companyWebsites",
                component: () =>
                    import("../views/CompanyWebsites/CompanyWebsites.vue")
            },
            {
                path: "/AlertToDepartments/:id?/",
                name: "alerts.alertToDepartments",
                component: () =>
                    import("../views/Alerts/AlertToDepartmentsPage.vue"),
                props: true
            },
            {
                path: "/AlertToEmployees/:id?/",
                name: "alerts.alertToEmployees",
                component: () =>
                    import("../views/Alerts/AlertToEmployeesPage.vue"),
                props: true
            },
            {
                path: "/AlertToPositions/:id?/",
                name: "alerts.alertToPositions",
                component: () =>
                    import("../views/Alerts/AlertToPositionsPage.vue"),
                props: true
            },
            {
                path: "/Policy",
                name: "policy.policy",
                component: () => import("../views/Policy/Policy.vue")
            },
            {
                path: "/usersPermissions/:id?/",
                name: "userPermissions.userPermissions",
                component: () => import("../views/Users/PermissionsPage.vue"),
                props: true
            },
            {
                path: "/Complaints",
                name: "complaints.complaints",
                component: () => import("../views/Complaints/Complaints.vue")
            },
            {
                path: "/Complaints/:id?/",
                name: "complaints.complaint",
                component: () => import("../views/Complaints/Complaint.vue"),
                props: true
            },
            {
                path: "/ManagementStructure",
                name: "structures.structure",
                component: () =>
                    import("../views/ManagementStructure/Structure.vue")
            },
            {
                path: "/Surveys",
                name: "surveys.surveys",
                component: () => import("../views/Surveys/Surveys.vue")
            },
            {
                path: "/Surveys/:id?/",
                name: "surveys.survey",
                component: () => import("../views/Surveys/Survey.vue"),
                props: true
            },

            {
                path: "/events/:eventType/:id?/",
                name: "events.events",
                component: () => import("../views/Events/Events.vue")
            },
            {
                path: "/notauthorize",
                name: "notauthorize",
                component: () => import("../views/NotAuthorized.vue")
            }
        ]
    },
    {
        path: "*",
        component: () => import("@/views/Home"),
        children: [
            {
                name: "404 Error",
                path: "",
                component: () => import("@/views/Error")
            }
        ]
    }
];

const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    routes
});

router.afterEach(toRoute => {
    window.document.title = i18n.t(toRoute.name);
});

export default router;
