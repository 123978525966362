<template>
    <v-app-bar id="app-bar" absolute app flat height="62">
        <v-btn class="mx-3" elevation="1" fab small @click="setDrawer(!drawer)">
            <v-icon v-if="value"> mdi-view-quilt </v-icon>
            <v-icon v-else> mdi-dots-vertical </v-icon>
        </v-btn>
        <v-spacer />
        <div class="mx-2" />
        <dashboard-core-settings />
        <v-menu bottom min-width="200px" rounded offset-y>
            <template v-slot:activator="{ attrs, on }">
                <v-badge
                    bordered
                    bottom
                    color="green accent-4"
                    dot
                    offset-x="15"
                    offset-y="15"
                >
                    <v-btn icon x-large v-on="on">
                        <v-avatar
                            v-bind="attrs"
                            size="40"
                            color="primary darken-2"
                        >
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <span
                                        v-bind="attrs"
                                        v-on="on"
                                        class="white--text text-h5"
                                        >{{ nameInitial }}</span
                                    >
                                </template>
                                <span>{{ userName }}</span>
                            </v-tooltip>
                        </v-avatar>
                    </v-btn>
                </v-badge>
            </template>
            <v-card>
                <v-list-item-content class="justify-center">
                    <div class="mx-auto text-center">
                        <v-avatar color="primary darken-2">
                            <span class="white--text text-h5">{{
                                nameInitial
                            }}</span>
                        </v-avatar>
                        <h3 class="mt-1">{{ userName }}</h3>
                        <!-- <p class="text-caption mt-1">
                            zaidkindman@gmail.com
                        </p> -->
                        <v-divider class="my-3"></v-divider>
                        <div v-if="is2FaVerified()">
                            <v-btn to="/profile" link depressed rounded text>
                                {{ $t("profile") }}
                            </v-btn>
                            <v-divider class="my-3"></v-divider>
                            <v-btn
                                to="/changePassword"
                                link
                                depressed
                                rounded
                                text
                            >
                                {{ $t("users.changePassword") }}
                            </v-btn>
                            <v-divider class="my-3"></v-divider>
                        </div>
                            <v-btn
                                color="red"
                                to="/auth"
                                depressed
                                rounded
                                text
                            >
                                {{ $t("appBar.logout") }}
                            </v-btn>
                        
                    </div>
                </v-list-item-content>
            </v-card>
        </v-menu>
    </v-app-bar>
</template>
<script>
import DashboardCoreSettings from "../components/Settings";
import { mapState, mapMutations } from "vuex";
export default {
    name: "DashboardCoreAppBar",
    components: {
        DashboardCoreSettings
    },
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        userName: localStorage.fullname,
        rtl: localStorage.rtl === "true"
    }),
    computed: {
        ...mapState(["drawer"]),
        nameInitial() {
            return this.userName
                .match(/(\b\S)?/g)
                .join("")
                .toUpperCase();
        }
    },
    methods: {
        ...mapMutations({
            setDrawer: "SET_DRAWER"
        })
    }
};
</script>
